import React from "react";
import golem from "../images/hero.jpg";
import { Container } from "reactstrap";
import Img from "gatsby-image";

const BannerImage = props => (
  <Img fluid={props.heroImage} className="img-fluid" />
);

export default BannerImage;
