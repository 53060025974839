import React from "react";
import Layout from "../components/layout";
import BannerImage from "../components/banner-image";
import IconWithText from "../components/icon-with-text";
import { Container, Row, Col, Button } from "reactstrap";
import GalleryFront from "../components/gallery-front";
import Contact from "../components/contact";
//images
import IconPencil from "../images/icons/pencil.png";
import IconGrid from "../images/icons/grid.png";
import IconStacks from "../images/icons/stacks.png";
import ImageSalesForceBooth from "../images/salesforce-booth.jpg";
import ImageKnightKing from "../images/knight-king.jpg";
import ImagePortal from "../images/portal.jpg";
import Img from "gatsby-image";

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Container>
          <BannerImage
            heroImage={this.props.data.heroImage.childImageSharp.fluid}
          />
        </Container>
        <div className="text-center">
          <h2 className="mt-4 bold">WE BRING YOUR PROPS</h2>
          <h2 className="bold blue">TO LIFE</h2>
        </div>
        <Container>
          <Row className="home-info-row">
            <Col md={4}>
              <Img
                fluid={this.props.data.salesforceImage.childImageSharp.fluid}
                className="img-fluid"
              />
            </Col>
            <Col md={8}>
              <h4>For Trade Shows & Conferences</h4>
              <p>
                It’s all about attracting attention and excitement, and what
                better way to do that than with high quality, amazing props that
                catch the eye, invite participation and enhance branding.
                <br />
                <br />
                We work with corporations, marketing companies, booth
                manufacturers, and others to build custom props that bring
                interest, participation, excitement and photo opportunities.
              </p>
            </Col>
          </Row>
          <div className="divider" />
          <Row className="home-info-row">
            <Col md={8}>
              <h4>For Advertising & Marketing</h4>
              <p>
                The world has gone high tech, and CGI and digital experiences
                abound. But what better way to stand out than by bringing a
                brand’s message to life in real 3D that potential customers and
                clients can touch, feel and take pictures with.
                <br />
                <br />
                We employ some of the best prop makers from Hollywood and the
                Los Angeles area to help bring your ideas to life, and to help
                you bring positive attention to your brand.
              </p>
            </Col>
            <Col md={4}>
              <Img
                fluid={this.props.data.knightImage.childImageSharp.fluid}
                className="img-fluid"
              />
            </Col>
          </Row>
          <div className="divider" />

          <Row className="home-info-row">
            <Col md={4}>
              <Img
                fluid={this.props.data.portalImage.childImageSharp.fluid}
                className="img-fluid"
              />
            </Col>
            <Col md={8}>
              <h4>For Events</h4>
              <p>
                Custom Props help make events more fun, and they make for great
                photo opportunities people can share with each other and across
                social platforms.
                <br />
                <br />
                We help designers, event planners and marketing executives bring
                their ideas to life and make their events something to remember.
              </p>
            </Col>
          </Row>

          <Row />
          <GalleryFront className="py-4" />
          <div className="py-3" />
          <Contact />
          <div className="py-4" />
          <div className="py-3" />
        </Container>
      </Layout>
    );
  }
}

export default IndexPage;

export const pageQuery = graphql`
  query {
    heroImage: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    knightImage: file(relativePath: { eq: "knight-king.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    portalImage: file(relativePath: { eq: "portal.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    salesforceImage: file(relativePath: { eq: "salesforce-booth.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
