import "./css/gallery-front.css";
import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { Link } from "gatsby";
import golem from "../images/gallery-front/golem.jpg";
import dior from "../images/gallery-front/dior.jpg";
import dragon from "../images/gallery-front/dragon.jpg";
import snow from "../images/gallery-front/snow.jpg";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const GalleryFront = props => (
  <StaticQuery
    query={graphql`
      query {
        image1: file(relativePath: { eq: "gallery-front/golem.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image2: file(relativePath: { eq: "gallery-front/dior.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image3: file(relativePath: { eq: "gallery-front/dragon.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image4: file(relativePath: { eq: "gallery-front/snow.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div className="py-5">
        <div className={"main-component"}>
          <div className="images">
            <Row>
              <Col xs="3">
                <Img
                  fluid={data.image1.childImageSharp.fluid}
                  className="img-fluid"
                />
              </Col>
              <Col xs="3">
                <Img
                  fluid={data.image2.childImageSharp.fluid}
                  className="img-fluid"
                />
              </Col>
              <Col xs="3">
                <Img
                  fluid={data.image3.childImageSharp.fluid}
                  className="img-fluid"
                />
              </Col>
              <Col xs="3">
                <Img
                  fluid={data.image4.childImageSharp.fluid}
                  className="img-fluid"
                />
              </Col>
            </Row>
          </div>
          <Link to="/gallery/" className="btn btn-primary" id="gallery-button">
            GALLERY
          </Link>
        </div>
      </div>
    )}
  />
);

export default GalleryFront;
